import { Injectable, inject } from '@angular/core';
import { BASE_URL_API, HttpService } from '@carwash-project/modules/core';
import { GetAllBranches } from '../models/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private readonly baseApi: string = inject(BASE_URL_API);
  private readonly httpService = inject(HttpService);
  private readonly mainPath = `${this.baseApi}branches`;

  public getAllBranches(
    query: GetAllBranches.Request | null
  ): Observable<GetAllBranches.Response> {
    return this.httpService.get(
      this.mainPath,
      query,
      'Error al consultar las sucursales'
    );
  }
}
