/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ProgressBarService } from '@carwash-project/modules/ui';
import { Store } from '@ngxs/store';
import {
  Observable,
  catchError,
  last,
  map,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { fileToFormDataFn } from '../../helpers/fileToFormData';
import {
  eventResponseFn,
  getPercentFn,
  progressBarControlFn,
} from '../../helpers/uploadFile';
import { EnvironmentModel, HttpHeadersModel, HttpOptions } from '../../models';
import {
  HttpQueryModel,
  HttpUploadFileModel,
} from '../../models/interfaces/http/http.interfaces';
import { StorageState } from '@carwash-project/modules/data-access/storage';
import { toSignal } from '@angular/core/rxjs-interop';
import { decryptFn } from '@carwash-project/modules/utils';
import { ENV_TOKEN } from '../../configs';

interface IEncryptedResponse {
  data: string;
  encrypted: boolean;
}

const decryptPipeFn = <T>(response: unknown, keyEncrypt: string):T => {
  const res = response as IEncryptedResponse;
  if (res?.encrypted) {
    return decryptFn(res.data, keyEncrypt) as T;
  }

  return response as T;
};

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly store = inject(Store);
  private readonly http = inject(HttpClient);
  private readonly token$ = this.store.select(StorageState.token);
  private token = toSignal(this.token$, { initialValue: null });
  private readonly progressBarService = inject(ProgressBarService);
  private readonly environment: EnvironmentModel = inject(ENV_TOKEN);

  private httpErrorHandler(message: string) {
    return (error: unknown) =>
      throwError(() => ({
        message,
        stack: error,
      }));
  }

  public get<T, K = HttpQueryModel | null>(
    url: string,
    query: K,
    message: string,
    headers?: HttpHeadersModel,
  ): Observable<T> {
    const options = new HttpOptions(this.token());
    options.setQueriesToHttpParams(query);

    if(headers){
      options.setHeaders(headers);
    }


    return this.http.get<unknown>(url, options).pipe(
      map<unknown, T>((response) =>
        decryptPipeFn(response, this.environment.keyEncrypt)
      ),
      catchError(this.httpErrorHandler(message))
    );
  }

  public post<T, K>(url: string, body: K, message: string, useToken=true): Observable<T> {
    const options = useToken ? new HttpOptions(this.token()):undefined;
    return this.http.post<unknown>(url, body, options).pipe(
      map<unknown, T>((response) =>
        decryptPipeFn(response, this.environment.keyEncrypt)
      ),
      catchError(this.httpErrorHandler(message))
    );
  }

  public patch<T, K>(url: string, body: K, message: string): Observable<T> {
    const options = new HttpOptions(this.token());
    return this.http.patch<T>(url, body, options).pipe(
      map<unknown, T>((response) =>
        decryptPipeFn(response, this.environment.keyEncrypt)
      ),
      catchError(this.httpErrorHandler(message))
    );
  }

  public put<T, K>(url: string, body: K, message: string): Observable<T> {
    const options = new HttpOptions(this.token());
    return this.http
      .put<T>(url, body, options)
      .pipe(
        map<unknown, T>((response) =>
          decryptPipeFn(response, this.environment.keyEncrypt)
        ),
        catchError(this.httpErrorHandler(message))
      );
  }

  public delete<T, K>(
    url: string,
    body: K | null,
    message: string
  ): Observable<T> {
    const options = new HttpOptions(this.token());
    options.setOption('body', body);
    return this.http
      .delete<T>(url, options)
      .pipe(
        map<unknown, T>((response) =>
          decryptPipeFn(response, this.environment.keyEncrypt)
        ),
        catchError(this.httpErrorHandler(message))
      );
  }

  public request<T, B, Q>(
    method: string,
    url: string,
    body: B | null,
    query: Q | null,
    message: string,
    responseType: string = 'json'
  ): Observable<T> {
    const options = new HttpOptions(this.token());
    options
      .setOption('body', JSON.stringify(body))
      .setOption('responseType', responseType)
      .setOption('observe', 'body')
      .setQueriesToHttpParams(query);

    return this.http
      .request<T>(method, url, options)
      .pipe(
        map<unknown, T>((response) =>
          decryptPipeFn(response, this.environment.keyEncrypt)
        ),
        catchError(this.httpErrorHandler(message))
      );
  }

  public upLoadFile<T>(
    method: 'POST' | 'PUT' | 'PATCH',
    url: string,
    body: HttpUploadFileModel,
    message: string
  ): Observable<T> {
    const formData = fileToFormDataFn(body);

    const request = new HttpRequest(method, url, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request<T>(request).pipe(
      takeUntil(this.progressBarService.cancelClick),
      map((event) => getPercentFn(event, body.file)),
      tap((event) => progressBarControlFn(event, this.progressBarService)),
      map((event) => eventResponseFn(event)),
      last(),
      map<unknown, T>((response) =>
        decryptPipeFn(response, this.environment.keyEncrypt)
      ),
      catchError(this.httpErrorHandler(message))
    );
  }
}
