import { GetAllBranches } from "../models";

export namespace BranchAction {

  export class ListAll {
    static readonly type = '[Branch] get all branches';
    constructor(public query: GetAllBranches.Request | null) {}
  }

  export class Failure {
    static readonly type = '[Branch] Load Error';
    constructor(public error: unknown) {}
  }
}
