<nz-modal
  nzClassName="custom-modal"
  [nzVisible]="isVisible"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzCentered]="true"
  [nzWidth]="720"
  (nzOnCancel)="isVisibleChange.emit(false)"
>
  <ng-template #modalContent>
    <div nz-row style="margin: 20px 0">
      <div nz-col nzSpan="24" style="font-size: 15px">
        ¿Tiene {{ model.length }} membresías adquiridas, desea adquirir una
        nueva?
      </div>
    </div>
    <nz-table #basicTable [nzData]="model">
      <thead>
        <tr>
          <th>#</th>
          <th>Plan</th>
          <th>Vehículo asignado</th>
          <th>Estado de la membresía</th>
        </tr>
      </thead>
      <tbody>
        @for (data of basicTable.data; track data) {
        <tr>
          <td>{{ $index + 1 }}</td>
          <td>{{ data.plan.name }}</td>
          <td>
            {{ data.vehicles.length ? data.vehicles[0].licensePlate : '-' }}
          </td>
          <td>
            @switch (data.status) { @case ('Activa') {
            <nz-tag [nzColor]="'green'">{{ data.status }}</nz-tag>
            } @case ('Suspendida') {
            <nz-tag [nzColor]="'purple'">{{ data.status }}</nz-tag>
            } @case ('Cancelacion programada') {
            <nz-tag [nzColor]="'orange'">{{ data.status }}</nz-tag>
            } @case ('Inactiva') {
            <nz-tag [nzColor]="'red'">{{ data.status }}</nz-tag>
            } @default {
            <nz-tag [nzColor]="'blue'">{{ data.status }}</nz-tag>
            } }
          </td>
        </tr>
        }
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="primary"
      class="btn-blue-default rounder"
      (click)="isVisibleChange.emit(false)"
    >
      Cancelar
    </button>
    <button
      nz-button
      nzType="primary"
      class="btn-blue rounder"
      routerLink="/view-user/plans/select-plan"
      [queryParams]="{
        btnRouter: ['view-user', 'plans', 'payment-method'],
        filterByBranch: true
      }"
    >
      Continuar
    </button>
  </ng-template>
</nz-modal>
