import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { differenceInMinutes } from 'date-fns';
import { StorageActions } from './storage.actions';
import { patch } from '@ngxs/store/operators';
import { AccessModel } from '../models';

export const STORAGE_TOKEN = new StateToken<StorageStateModel>(
  'carWashStorage'
);

export interface StorageStateModel {
  loggedIn: boolean;
  token: string | null;
  refreshToken: string | null;
  user: {
    user_id: number;
    profile_id: number;
    access:AccessModel[];
    accessPanel:boolean;
  } | null;
  waitTime: {
    init: Date;
    finish: Date;
  } | null;
  waitTimePayment: {
    init: Date;
    finish: Date;
  } | null;
}

const initialState: StorageStateModel = {
  loggedIn: false,
  token: null,
  refreshToken: null,
  user: null,
  waitTime: null,
  waitTimePayment: null,
};

@State<StorageStateModel>({
  name: STORAGE_TOKEN,
  defaults: initialState,
})
@Injectable()
export class StorageState {
  @Selector()
  public static token(state: StorageStateModel) {
    return state.token;
  }

  @Selector()
  public static refreshToken(state: StorageStateModel) {
    return state.refreshToken;
  }

  @Selector()
  public static waitTime(state: StorageStateModel) {
    return state.waitTime;
  }

  @Selector()
  public static waitTimePayment(state: StorageStateModel) {
    return state.waitTimePayment;
  }

  @Selector()
  public static waitTimeMinutes(state: StorageStateModel) {
    if (!state.waitTime) return 0;
    const dateLeft = new Date(state.waitTime.finish);
    const dateRight = new Date();

    return differenceInMinutes(dateLeft, dateRight);
  }

  @Selector()
  public static waitTimePaymentMinutes(state: StorageStateModel) {
    if (!state.waitTimePayment) return 0;
    const dateLeft = new Date(state.waitTimePayment.finish);
    const dateRight = new Date();

    return differenceInMinutes(dateLeft, dateRight);
  }

  @Action(StorageActions.Save)
  public onSave(
    ctx: StateContext<StorageStateModel>,
    { payload }: StorageActions.Save
  ) {
    ctx.setState(payload);
  }

  @Action(StorageActions.Update)
  public onUpdate(
    ctx: StateContext<StorageStateModel>,
    { payload }: StorageActions.Update
  ) {
    ctx.setState(patch<StorageStateModel>(payload));
  }

  @Action(StorageActions.UpdateRefreshToken)
  public onUpdateRefreshToken(
    ctx: StateContext<StorageStateModel>,
    { token }: StorageActions.UpdateRefreshToken
  ) {
    ctx.setState(patch<StorageStateModel>({
      refreshToken: token,
    }));
  }

  @Action(StorageActions.UpdateToken)
  public onUpdateToken(
    ctx: StateContext<StorageStateModel>,
    { token }: StorageActions.UpdateToken
  ) {
    ctx.setState(patch<StorageStateModel>({
      token: token,
    }));
  }
}
