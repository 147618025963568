<div class="card">
  <div class="card-inner">
    <div class="card-front">
      <div class="card-bg"></div>
      <div class="card-glow"></div>
      <div class="card-logo">
        @switch (model?.cardType) { @case ("MASTERCARD") {
        <img
          class="mc"
          src="assets/images/credit-cards/mc.png"
          class="credit-cards-logo"
        />
        } @case ("VISA") {
        <img
          src="assets/images/credit-cards/visa.png"
          class="credit-cards-logo"
        />
        } @case ("AMERICANEXPRESS") {
        <img
          class="mc"
          src="assets/images/credit-cards/ame.png"
          style="width: 45px"
          class="credit-cards-logo"
        />
        } @case ("DINERS") {
        <img
          class="mc"
          src="assets/images/credit-cards/dci.png"
          style="width: 80px"
          class="credit-cards-logo"
        />
        } @default {
        <span class="name-credit-card">{{ model?.cardType }}</span>
        } }
      </div>
      <div class="card-contactless">
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="56">
          <path
            fill="none"
            stroke="#f9f9f9"
            stroke-width="6"
            stroke-linecap="round"
            d="m35,3a50,50 0 0,1 0,50M24,8.5a39,39 0 0,1 0,39M13.5,13.55a28.2,28.5
0 0,1 0,28.5M3,19a18,17 0 0,1 0,18"
          />
        </svg>
      </div>
      <div class="card-chip"></div>
      <div class="card-holder">{{ model?.cardOwner }}</div>
      <div class="card-number">**** **** **** {{ model?.last4Numbers }}</div>
      <div class="card-valid">
        {{ getExpirationDate(model?.expirationDate) }}
      </div>
    </div>
    <div class="card-back">
      <div class="card-signature"></div>
      <div class="card-seccode">***</div>
    </div>
  </div>
</div>
