/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable, inject } from '@angular/core';
import {
  MAIN_MENU,
  MAIN_MENU_USER,
  menuFilterFn,
} from '@carwash-project/modules/core';
import {
  BranchState,
  BranchStateModel,
  GetAllBranches
} from '@carwash-project/modules/data-access/branch';
import { MainMenuModel, TableData } from '@carwash-project/modules/ui';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { catchError, mergeMap, tap, throwError } from 'rxjs';
import { GetAllUsersModel, GetUserByIdModel, UsersModel } from '../models';
import { UserService } from '../services/user.service';
import { UserActions } from './user.actions';

export interface IBranchSelect {
  id: number;
  name: string;
}

const transformDataBranch = (
  id: number | null,
  branches: GetAllBranches.Response | null
): IBranchSelect | null => {
  if (!id || !branches?.results) return null;
  const name = branches.results.find((b) => b.id === id)?.name;
  if (!name) return null;

  return {
    id,
    name,
  };
};

/** Models */
export const USER_TOKEN = new StateToken<UserStateModel>('user');

const BRANCH_SELECTED_ROLES = ['Super administrador'];

export interface UserStateModel {
  users: GetAllUsersModel.Response | null;
  user: GetUserByIdModel.Response | null;
  branchId: number | null;
  access: UsersModel.Access[];
  userById: GetUserByIdModel.Response | null;
  loading: boolean;
  error: unknown;
}

const initialState: UserStateModel = {
  users: null,
  user: null,
  branchId: null,
  access: [],
  userById: null,
  loading: false,
  error: null,
};

/** State */
@State<UserStateModel>({
  name: USER_TOKEN,
  defaults: initialState,
})
@Injectable()
export class UserState {
  private readonly userService = inject(UserService);

  @Selector()
  public static user(state: UserStateModel) {
    return state.user;
  }

  @Selector([BranchState])
  public static branch(
    state: UserStateModel,
    branchState: BranchStateModel
  ): IBranchSelect | null {
    if(!state.user) return null;

    if (BRANCH_SELECTED_ROLES.includes(state.user.role.name)) {
      return transformDataBranch(state.branchId, branchState.branches);
    }
    return state.user?.branch
      ? transformDataBranch(Number(state.user?.branch), branchState.branches)
      : null;
  }

  @Selector()
  public static canSelectedOptionBranch(state: UserStateModel) {
    if (!state.user) return false;
    return BRANCH_SELECTED_ROLES.includes(state.user?.role.name ?? '');
  }

  @Selector()
  public static role(state: UserStateModel) {
    return state.user?.role;
  }

  @Selector()
  public static users(state: UserStateModel) {
    return state.users;
  }

  @Selector()
  public static usersById(state: UserStateModel) {
    return state.userById;
  }

  @Selector()
  public static access(state: UserStateModel) {
    return state.access;
  }

  @Selector()
  public static isUserAdmin(state: UserStateModel) {
    const adminsIDs = [2, 3];
    return state.user && adminsIDs.includes(state.user.role.id);
  }

  @Selector()
  public static menuDashboard(state: UserStateModel): MainMenuModel[] {
    if (!state.user) return [];
    return state.user.role.id == 1
      ? MAIN_MENU_USER
      : menuFilterFn(MAIN_MENU, state.access);
  }

  @Selector()
  public static userPersonalInfo(state: UserStateModel) {
    return state.user
      ? [
          `${state.user.name} ${state.user.lastname}`,
          state.user.email,
          state.user.phone,
        ]
      : [];
  }

  @Selector()
  public static userBillingInfo(state: UserStateModel) {
    return state.user
      ? [
          state.user.billingNIT ?? '',
          state.user.billingAddress ?? '',
          state.user.billingEmail ?? '',
        ]
      : [];
  }

  @Selector()
  public static usersTable(state: UserStateModel) {
    return new TableData(state.users?.results ?? [], state.users?.totalCount);
  }

  @Selector()
  public static loading(state: UserStateModel) {
    return state.loading;
  }

  /** Action Handle Error */
  @Action(UserActions.Failure)
  public handlerError(
    ctx: StateContext<UserStateModel>,
    { error }: UserActions.Failure
  ) {
    // const err = error as ErrorMessageModel;

    ctx.patchState({
      error,
      loading: false,
    });

    // if (err.stack && err.stack.status == 401) {
    //   ctx.dispatch(new AuthActions.Logout());
    // }

    return throwError(() => error);
  }

  @Action(UserActions.LoadBranchId)
  public loadBranchId(
    ctx: StateContext<UserStateModel>,
    { branchId }: UserActions.LoadBranchId
  ) {
    ctx.patchState({
      branchId,
    });
  }

  /** Async */
  @Action(UserActions.List)
  public getAllUsers(
    ctx: StateContext<UserStateModel>,
    { query }: UserActions.List
  ) {
    ctx.patchState({ loading: true });
    return this.userService.getAllUsers(query).pipe(
      tap((payload) => {
        ctx.patchState({
          loading: false,
          users: payload,
        });
      }),
      catchError((error) => ctx.dispatch(new UserActions.Failure(error)))
    );
  }

  @Action(UserActions.Detail)
  public getUserById(
    ctx: StateContext<UserStateModel>,
    { userId }: UserActions.Detail
  ) {
    return this.userService.getUserById(userId).pipe(
      tap((payload) => {
        ctx.patchState({
          userById: payload,
        });
      }),
      catchError((error) => ctx.dispatch(new UserActions.Failure(error)))
    );
  }

  @Action(UserActions.Update)
  public updateUserInfo(
    ctx: StateContext<UserStateModel>,
    { body }: UserActions.Update
  ) {
    const userId = ctx.getState().user?.id as number;
    return this.userService.updateUserInfo(body, userId).pipe(
      mergeMap(() => ctx.dispatch(new UserActions.DetailUserLogged())),
      catchError((error) => ctx.dispatch(new UserActions.Failure(error)))
    );
  }

  @Action(UserActions.UpdateUserByAdmin)
  public updateUserInfoByAdmin(
    ctx: StateContext<UserStateModel>,
    { body, userID }: UserActions.UpdateUserByAdmin
  ) {
    ctx.patchState({ loading: true });
    return this.userService.updateUserInfo(body, userID).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new UserActions.Failure(error)))
    );
  }

  @Action(UserActions.DetailUserLogged)
  public getUserByToken(ctx: StateContext<UserStateModel>) {
    return this.userService.getUserInfo().pipe(
      tap((payload) => {
        ctx.patchState({
          user: {
            ...payload,
            billingNIT: payload.billingNIT ?? payload.NIT,
            billingEmail: payload.billingEmail ?? payload.email,
          },
          access: payload.role.access,
        });
      }),
      catchError((error) => ctx.dispatch(new UserActions.Failure(error)))
    );
  }
}
