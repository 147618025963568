/** Filter Date */

import { DropdownModel } from '../models';

export const filterDateConfigs: DropdownModel = {
  buttonLabel: 'Fecha',
  activeLabel: 'Filtrar',
  menuTitle: 'Filtrar por',
  selected: 'day',
  menu: [
    {
      key: 'day',
      label: 'Día',
    },
    {
      key: 'week',
      label: 'Semana',
    },
    {
      key: 'month',
      label: 'Mes',
    },
    {
      key: 'range',
      label: 'Rango',
    },
  ],
};

export const filterDateConfigsNewDashboard: DropdownModel = {
  buttonLabel: 'Filtrar por fecha',

  menuTitle: 'Filtrar por',
  selected: undefined,
  menu: [
    {
      key: 'now',
      label: 'Hoy',
    },
    {
      key: 'day',
      label: 'Día',
    },
    {
      key: 'week',
      label: 'Semana',
    },
    {
      key: 'month',
      label: 'Mes',
    },
    {
      key: 'range',
      label: 'Rango',
    },
  ],
};

export const filterDateWithHourConfigs: DropdownModel = {
  buttonLabel: 'Fecha',
  activeLabel: 'Filtrar',
  menuTitle: 'Filtrar por',
  selected: 'hour',
  menu: [
    {
      key: 'hour',
      label: 'Hora',
    },
    {
      key: 'day',
      label: 'Día',
    },
    {
      key: 'week',
      label: 'Semana',
    },
    {
      key: 'month',
      label: 'Mes',
    },
    {
      key: 'range',
      label: 'Rango',
    },
  ],
};

export const filterBranchConfigs: DropdownModel = {
  buttonLabel: 'Filtrar',
  menuTitle: 'Filtrar por sucursal',
  menu: [],
};
