/* eslint-disable @nx/enforce-module-boundaries */
import { Component, inject } from '@angular/core';
import { UserState } from '@carwash-project/modules/data-access/user';
import { UTILS } from '@carwash-project/modules/utils';
import { Store } from '@ngxs/store';
import { UI_NGZORRO } from '../ui-ngzorro';

@Component({
  selector: 'ui-branch-tag',
  standalone: true,
  imports: [UI_NGZORRO, UTILS],
  template: `@if ( (branchSelected$ | async) ) {<nz-tag nzColor="warning">{{
      (branchSelected$ | async)?.name
    }}</nz-tag
    >}`,
  styles: ``,
})
export class UiBranchTagComponent {
  public store = inject(Store);
  public branchSelected$ = this.store.select(UserState.branch);
}
