import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BranchService } from '../services/branch.service';
import { GetAllBranches } from '../models';
import { BranchAction } from './branch.actions';
import { catchError, tap, throwError } from 'rxjs';

export interface BranchStateModel {
  branches: GetAllBranches.Response | null;
  loading: boolean;
  error: unknown;
}

const initialState: BranchStateModel = {
  branches: null,
  loading: false,
  error: null,
};

@State<BranchStateModel>({
  name: 'branch',
  defaults: initialState,
})
@Injectable()
export class BranchState {
  private readonly branchService = inject(BranchService);

  @Selector()
  public static branches(state: BranchStateModel) {
    return state.branches;
  }

  @Selector()
  public static loading(state: BranchStateModel) {
    return state.loading;
  }

  /** Action Handle Error */
  @Action(BranchAction.Failure)
  public handleError(
    ctx: StateContext<BranchStateModel>,
    { error }: BranchAction.Failure
  ) {
    ctx.patchState({
      error,
      loading: false,
    });
    return throwError(() => error);
  }

  @Action(BranchAction.ListAll)
  public getUserSubscriptions(
    ctx: StateContext<BranchStateModel>,
    { query }: BranchAction.ListAll
  ) {
    ctx.patchState({ loading: true });
    return this.branchService.getAllBranches(query).pipe(
      tap((payload) => {
        ctx.patchState({
          branches: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new BranchAction.Failure(error)))
    );
  }
}
